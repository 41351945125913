import React from 'react'
import { formatDate } from '../../../utils/utility'

function TransactionCard({ transaction, index }) {

    return <div className='shadow-lg bg-white mt-2 rounded-xl p-2'>
        <div className='flex justify-between'>
            <div className='flex gap-2 items-center'>
                <img src={transaction?.from_currency?.icon} alt='' className='w-6 h-6 rounded-full' />
                <div>
                    <p>{transaction?.from_currency?.name}</p>
                    <p className='text-[16px] font-bold'>{transaction?.from_balance}</p>
                    {/* <p className='text-[11px]'>{transaction.safe.name}</p> */}
                </div>
            </div>
            <div className='flex items-center'>
                <p className='text-gray-300 text-md'> -----></p>
            </div>
            <div className='flex gap-2 items-center'>
                <img src={transaction?.to_currency?.icon} alt='' className='w-6 h-6 rounded-full' />
                <div>
                    <p>{transaction?.to_currency?.name}</p>
                    <p className='text-[16px] font-bold'>{transaction?.to_balance}</p>
                    {/* <p className='text-[11px]'>{transaction.safe.name}</p> */}
                </div>
            </div>
        </div>
        <hr className='my-2' />
        <div>
            <p className='text-gray-400'>{transaction?.note}</p>
        </div>
        <hr className='my-2' />
        <div className='flex justify-between'>
            <div className='flex gap-1 items-center'>
                <p className='text-gray-500 font-medium'>بواسطه</p>
                <p className='text-gray-500 '>{transaction?.creater?.full_name.split(' ').slice(0, 2).join(' ')}</p>
            </div>


            <p className='text-gray-400'>{formatDate(transaction?.created_at)}</p>
        </div>
    </div>
}

export default TransactionCard