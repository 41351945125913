import React from 'react'

function UserCard({ data }) {
    return (
        <div className='flex gap-2 bg-white shadow-md p-2 m-2 rounded-lg items-center py-3'>
            <img src='https://cdn-icons-png.flaticon.com/512/149/149071.png' alt='' className='w-6 h-6' />
            <div>
                <p className='text-md font-medium'>{data.full_name}</p>
                <div className='flex gap-2 items-center'>
                    <div className={`w-2 h-2 rounded-full ${data?.status == 1 ? ' bg-greenColor' : ' bg-rose-700'}`}></div>
                    <p className={`text-sm   ${data?.status == 1 ? ' text-greenColor' : ' text-rose-700'}`}>
                        {data?.status == 1 ? 'نشط' : 'غير نشط'}

                    </p>
                </div>
            </div>
        </div>
    )
}

export default UserCard