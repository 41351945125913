import React, { useEffect, useState } from 'react'
import { Doughnut } from 'react-chartjs-2';
import { useParams } from 'react-router-dom';
import GeneralServices from '../../services/GeneralServices';
import { formatDate } from '../../utils/utility';
import TransactionCard from './Components/TransactionCard';
import MyChart from '../../components/MyChart';

function WalletTransactionsPage() {

    // get id from url
    const { id } = useParams();


    const [transactions, setTransactions] = useState([])
    const [currencies, setCurrencies] = useState([])

    const service = new GeneralServices()

    const fetchCurrencies = async () => {
        try {
            const res = await service.get('currencies')
            setCurrencies(res)
        } catch (error) {
            console.log('error', error)
        }
    }

    const fetchTransactions = async () => {
        try {
            const res = await service.get('transaction')
            setTransactions(res)
        } catch (error) {
            console.log('error', error)
        }
    }

    const handleAprove = async (id) => {
        try {
            const res = await service.post(`transaction/approve/${id}`)
            fetchTransactions()
            showSuccessToast('تم التحويل بنجاح')
        } catch (error) {
            console.log('error', error)
        }
    }


    useEffect(() => {
        fetchTransactions()
        fetchCurrencies()

    }, [])





    return (
        <>
            <div className='flex justify-between'>
                <p>الدرهم الامارتي</p>
                <div className='flex items-center gap-1'>
                    <p className='text-gray-300 select-none'>اليوم</p>
                </div>
            </div>
            <MyChart />
            <IncomExpensData />
            {transactions.map((transaction, index) => <TransactionCard index={index} transaction={transaction} />)}

        </>
    )
}

const IncomExpensData = () => {
    return <div className='rounded-xl mt-2 shadow-md p-2 flex justify-between py-3'>
        <div className='flex  items-center gap-1'>
            <div className='bg-[#ffad57] w-10 h-10 rounded-full flex justify-center items-center'>

                <p className='text-white'>△</p>
            </div>
            <div>
                <p className='text-md font-medium'>838373 SDG</p>
                <p className='text-gray-400'>الخارج</p>
            </div>
        </div>
        <div className='flex  items-center gap-1'>
            <div className='bg-[#4bd07f] w-10 h-10 rounded-full flex justify-center items-center'>

                <p className='text-white font-semibold'>▼</p>

            </div>
            <div>
                <p className='text-md font-medium'>838373 SDG</p>
                <p className='text-gray-400'>الدخل</p>
            </div>
        </div>

    </div>
}

export default WalletTransactionsPage