// Import necessary libraries
import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';


Chart.defaults.font.family = "'Baloo Bhaijaan 2', cursive";

// Register the components you want to use with Chart.js
Chart.register(ArcElement, Tooltip, Legend);



// Create the Doughnut chart component
const DoughnutChart = ({ data, display = false }) => {
    // Define your data and options


    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: display,
                position: 'top',

            },
            tooltip: {
                enabled: true,
            },
            centerText: {
                display: display,
                text: 'بيانات الخزنه',
                color: 'black',
                font: {
                    size: 20
                }
            }
        },
    };

    return (


        <div className='w-full'>
            <Doughnut data={data} options={options} />
        </div>
    );

}
export default DoughnutChart;
