import React from 'react'

const ListTile = ({ title, subtitle, icon, prefix, suffix }) => {
    return (
        <div className="flex items-center gap-4 p-1 bg-white rounded-lg shadow-md">
            {prefix && <div className={`w-10 h-10 bg-${icon.color}-500 rounded-full flex items-center justify-center`}>{prefix}</div>}
            {icon && <div className={`w-10 h-10 bg-${icon.color}-500 rounded-full flex items-center justify-center`}>{icon.component}</div>}
            <div className="flex flex-col">
                <div className="flex items-center gap-2">
                    <h3 className="font-bold">{title}</h3>
                    {suffix && <div className={`w-5 h-5 bg-${icon.color}-500 rounded-full flex items-center justify-center`}>{suffix}</div>}
                </div>
                <p className="text-sm text-gray-500">{subtitle}</p>
            </div>
        </div>
    )
}

export default ListTile