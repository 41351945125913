import React, { useEffect, useState } from 'react'
import Button from '../../components/Button';
import { showErrorToast, showSuccessToast } from '../../utils/toastUtils';
import GeneralServices from '../../services/GeneralServices';
import Input from '../../components/Input';
import { Link } from 'react-router-dom';
import { formatNumber } from '../../utils/utility';

function TransactionPage() {

    const [names, setNames] = useState([]);



    const [transaction, setTransaction] = useState({
        from: {
            id: 1,
            name: 'درهم اماراتي',
            short_name: 'درهم',
            amount: 1,
            icon: 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/cb/Flag_of_the_United_Arab_Emirates.svg/255px-Flag_of_the_United_Arab_Emirates.svg.png',
            currency_id: 1
        },
        to: {
            id: 2,
            name: 'جنيه سوداني',
            short_name: 'جنيه',
            amount: 1,
            icon: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/01/Flag_of_Sudan.svg/800px-Flag_of_Sudan.svg.png',
            currency_id: 1
        },
        note: '',
    })


    const service = new GeneralServices();
    // سعر التحويل
    const [exchangeRate, setExchangeRate] = useState(1)

    const handleChangeCurrency = (type, currency) => {
        if (type === 'from' && currency.name !== transaction.to.name) {
            setTransaction({
                ...transaction,
                from: {
                    name: currency.name,
                    short_name: currency.short_name,
                    icon: currency.icon,
                    currency_id: currency.id
                }
            })
        } else if (currency.name === transaction.to.name) {
            showErrorToast("لا يمكن التحويل من والى نفس العمله")
        }

        if (type === 'to' && currency.name !== transaction.from.name && currency.name !== transaction.from.name) {
            setTransaction({
                ...transaction,
                to: {
                    name: currency.name,
                    short_name: currency.short_name,
                    icon: currency.icon,
                    currency_id: currency.id
                }
            })
        } else if (currency.name === transaction.from.name) {
            showErrorToast("لا يمكن التحويل من والى نفس العمله")
        }
    }



    const handleExchangeRate = (rate) => {

        setExchangeRate(rate)

        setTransaction({
            ...transaction,
            to: {
                ...transaction.to,
                amount: transaction.from.amount * rate
            }
        })
    }




    const handleTransaction = async () => {
        try {
            const res = await service.post(`transaction`, transaction)
            setTransaction({
                ...transaction,
                to: {
                    ...transaction.from,
                    name: res[0].name,
                    icon: res[0].icon,
                    short_name: res[0].short_name,
                    currency_id: res[0].id,
                    amount: 1
                },
                from: {
                    ...transaction.to,
                    name: res[1].name,
                    icon: res[1].icon,
                    short_name: res[1].short_name,
                    currency_id: res[1].id,
                    amount: 1
                },
                note: '',


            })
            showSuccessToast('تمت العملية بنجاح')
        } catch (error) {
            console.log('error', error)
            if (error.state === 400) {

                showErrorToast(error.message)
            }
        }

    }



    const fetchCurrencies = async () => {
        try {
            const res = await service.get('currencies')
            setNames(res)
            setTransaction({
                ...transaction,
                to: {
                    ...transaction.from,
                    name: res[0].name,
                    icon: res[0].icon,
                    short_name: res[0].short_name,
                    currency_id: res[0].id
                },
                from: {
                    ...transaction.to,
                    name: res[1].name,
                    icon: res[1].icon,
                    short_name: res[1].short_name,
                    currency_id: res[1].id
                }
            })
        } catch (error) {
            console.log('error', error)
        }
    }


    useEffect(() => {
        fetchCurrencies()
    }, [])

    return (
        <div>

            <div className='mx-[12px] py-2 flex justify-between'>
                <h1 className='font-bold text-lg'>تحويل</h1>
                <Link to='/transaction/list' className='font-bold text-lg'>عرض</Link>
            </div>
            <div className='flex justify-center'>
                <div className='flex gap-2'>
                    <p>من</p>


                    <p>{transaction.from.name}</p>
                    <p className='font-bold'>لى</p>
                    <p>{transaction.to.name}</p>


                </div>
            </div>

            <div className='shadow-lg mt-4 p-4 bg-white rounded-xl '>
                <div className=' justify-between flex'>
                    <div className='flex gap-2'>
                        <img src={transaction.from.icon} alt={transaction.from.name} className='w-6 h-6 rounded-full' />
                        <h1 className='font-bold'>{transaction.from.name}</h1>
                    </div>
                    <div className='flex items-end justify-start'>
                        <Input type="number" placeholder='المبلغ'
                            className='font-bold  text-lg text-right w-[150px]'
                            borderStyle='none'
                            onChange={(e) => setTransaction({ ...transaction, from: { ...transaction.from, amount: e.target.value } })}
                            value={transaction.from.amount} />
                    </div>

                </div>
                <div className='flex gap-2'>
                    {names.map((item, index) => <Currency
                        onClick={() => handleChangeCurrency('from', item)}
                        status={transaction.from.short_name == item.short_name ? true : false}
                        name={item.short_name}
                        key={index}
                        warning_balance={item.warning_balance}
                        balance={item.balance}
                    />)}

                </div>
            </div>



            <div className='shadow-lg mt-4 p-4 bg-white rounded-xl flex items-center  justify-between'>
                <div>
                    <h1 className='text-lg font-medium'>الريت</h1>
                </div>

                <div className='text-right'>

                    <Input
                        className='font-bold text-lg  p-1 px-2 w-[150px] text-right'
                        type="number"
                        size='sm'
                        onChange={(e) => handleExchangeRate(e.target.value)}
                        value={exchangeRate}
                        placeholder='القيمه' />

                </div>

                {/* <div>
                    <p className='text-right text-sm font-medium'>عكس العمليه</p>
                </div> */}
            </div>


            <div className='shadow-lg mt-4 p-4 bg-white rounded-xl'>
                <div className='flex justify-between'>
                    <div className='flex gap-2'>
                        <img src={transaction.to.icon} alt={transaction.to.name} className='w-6 h-6 rounded-full' />
                        <h1 className='font-bold'>{transaction.to.name}</h1>
                    </div>
                    <div>
                        {formatNumber(transaction.to.amount)}
                    </div>
                </div>
                <div className='text-right '>
                    <input type="number" className='font-bold text-lg  p-1 px-2 w-[150px]' placeholder='المبلغ' value={transaction.to.amount} />



                </div>

                <div className='flex gap-2'>
                    {names.map((item, index) => <Currency
                        onClick={() => handleChangeCurrency('to', item)} data={item}
                        status={transaction.to.short_name == item.short_name ? true : false}
                        name={item.short_name}
                        key={index}
                        warning_balance={item.warning_balance}
                        balance={item.balance}
                    />)}


                </div>

            </div>

            <div className='shadow-lg mt-4 p-4 bg-white rounded-xl'>
                <h1 className='text-lg font-medium'>ملاحظات</h1>
                <div className='  text-sm border rounded-xl'>
                    <textarea onChange={(e) => setTransaction({ ...transaction, note: e.target.value })} value={transaction.note} className='  p-2 w-full'>
                        {transaction.note}
                    </textarea>
                </div>
            </div>
            <Button onClick={handleTransaction} text='تحويل' />
            <div className='h-[100px]' />


        </div>
    )
}


const Currency = ({ onClick, status, name, warning_balance = 0, balance = 0 }) => {
    return <p onClick={onClick} className={`text-[14px] cursor-pointer px-2 py-[1px] flex items-center gap-1 rounded-full ${status == true ? 'bg-primary  text-white' : 'border'}`}>
        <div className={`w-2 h-2 rounded-full ${warning_balance < balance ? ' bg-red-700' : 'bg-greenColor'}`}></div>
        {name}</p>

}
export default TransactionPage