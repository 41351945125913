import React, { useEffect, useState } from "react";
import { Link, BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./routes/routes";
import "./App.css";

import * as PusherPushNotifications from "@pusher/push-notifications-web";


//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";
import Login from "./pages/Login";
import AuthContextProvider from "./contexts/AuthContextProvider";
import { Toaster } from "react-hot-toast";

//core
// import "primereact/resources/primereact.min.css";
// import AuthContextProvider, { AuthContext } from "./contexts/AuthContextProvider";
// import { Toaster } from "react-hot-toast";
// import OneSignal from "react-onesignal";
// import runOneSignal from "./utils/onesignal";


const App = () => {


  const { user } = JSON.parse(localStorage.getItem('user')) ?? []

  // const beamsClient = new PusherPushNotifications.Client({
  //   instanceId: "608af209-9cef-40c2-88dc-a42349540956",
  // });


  useEffect(() => {
    // runOneSignal();
  })
  useEffect(() => {

    // if (user?.email) {

    //   beamsClient
    //     .start()
    //     .then((beamsClient) => beamsClient.getDeviceId())
    //     .then((deviceId) =>
    //       console.log("Successfully registered with Beams. Device ID:", deviceId)
    //     )
    //     .then(() => beamsClient.addDeviceInterest(user.email))
    //     .then(() => beamsClient.getDeviceInterests())
    //     .then((interests) => console.log("Current interests:", interests))
    //     .catch(console.error)
    // } else {
    //   console.log('user', user)
    // }
  }, [])





  // return <Login />
  // return <Mobile />
  return (

    <AuthContextProvider>
      <Toaster reverseOrder={false} />
      <Router>
        <AppRoutes />
      </Router>
    </AuthContextProvider>
  );
};

const Mobile = () => {


  const categories = [
    {
      name: "لحوم الضان",
      color: "#ea9f2e",
      icon: "fa fa-utensils",
    },
    {
      name: "لحوم حاشى",
      color: "#18b3bd",
      icon: "fa fa-utensils",
    },
    {
      name: "لحوم دجاج",
      color: "#779c3a",
      icon: "fa fa-utensils",
    },
    {
      name: "لحوم سمك",
      color: "#dd3f61",
      icon: "fa fa-utensils",
    },
    {
      name: "لحوم الضان",
      color: "blue",
      icon: "fa fa-utensils",
    },
    {
      name: "لحوم الضان",
      color: "blue",
      icon: "fa fa-utensils",
    },
    {
      name: "لحوم الضان",
      color: "blue",
      icon: "fa fa-utensils",
    },
    {
      name: "لحوم الضان",
      color: "blue",
      icon: "fa fa-utensils",
    },
    {
      name: "لحوم الضان",
      color: "blue",
      icon: "fa fa-utensils",
    },
    {
      name: "لحوم الضان",
      color: "blue",
      icon: "fa fa-utensils",
    },
    {
      name: "لحوم الضان",
      color: "blue",
      icon: "fa fa-utensils",
    },
    {
      name: "لحوم الضان",
      color: "blue",
      icon: "fa fa-utensils",
    },
  ]
  // get width as variable
  const width = window.innerWidth


  return (
    <>
      <div className="bg-rose-500 w-full shadow-lg p-2  overflow-hidden flex justify-between items-center">
        <div className="w-10 h-10 rounded-full bg-green-50">

        </div>

        <div>
          <img width="30" height="30" src="https://img.icons8.com/ios/50/ffffff/appointment-reminders--v1.png" alt="appointment-reminders--v1" />
        </div>
      </div>



      <div className="p-1">
        {/* header */}



        <div className="w-full shadow-lg my-2 rounded-2xl overflow-hidden">
          <img src="https://saudiarabia.britishcouncil.org/sites/default/files/website_630x354.jpg" />
        </div>
        <div className="flex gap-2 overflow-scroll py-3">
          {categories.map((category, index) => (
            <div key={index} className="text-center">
              <div
                className="cursor-pointer rounded-[12px] flex mb-2"
                style={{
                  width: `${width / 4}px`,
                  height: `${width / 4}px`,
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: category.color,
                }}
              >
                <img
                  width="50"
                  height="50"
                  style={{ filter: 'brightness(100%)' }}
                  src="https://img.icons8.com/ios/50/fish--v1.png"
                  alt="fish--v1"
                />
              </div>
              <h2 className=" text-[18px] md:text-[16px] sm:text-[14px]">{category.name}</h2>
            </div>
          ))}
        </div>

        <div className="row m-1">

          {categories.map((category, index) => (
            <div className="p-1 mt-2" style={{ width: "50%" }}>
              <div className="shadow-md cursor-pointer rounded-[12px] overflow-hidden relative">
                <div
                  className="w-full h-[250px] bg-cover bg-center relative"
                  style={{
                    backgroundImage: `url("https://media.alalam.ir/uploads/855x495/2020/11/21/160595559301239300.jpg")`,
                  }}
                >
                  <div
                    className="absolute top-2 right-0 bg-stone-500 text-white text-[12px]  px-2 py-1 rounded-bl-lg"
                    style={{ top: "0px", right: "0px" }}
                  >
                    من 8 الى 8 كيلو
                  </div>
                  <div
                    className="absolute top-2 right-0 bg-yellow-500 text-white text-[12px] font-bold px-2 py-1 rounded-e-lg "
                    style={{ top: "30px", right: "0px" }}
                  >
                    مميز
                  </div>
                </div>
                <div className="p-3">
                  <p className="text-[18px] text-gray-500 font-medium">{category.name}</p>
                  <div className="flex justify-between">
                    <p className="text-[14px] text-gray-600">2929 ريال</p>
                    <div className="row bg-rose-500 p-1 rounded-full px-2 text-[14px] text-white flex items-center">
                      <p className="mr-1">+</p>
                      <p>أضف</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          ))}
        </div>
      </div>
      <div className="fixed bottom-0 w-full bg-white border-t shadow-top" >
        <div className="flex justify-between w-full text-center pt-2 px-2">


          <a href="/" className="flex flex-col items-center justify-center text-slate-600">
            <img
              height="25"
              width="25"
              src="/icons/home.png"

            />
            <p className="text-rose-600">الرئيسية</p>
          </a>

          <a href="/" className="flex flex-col items-center justify-center text-slate-600">
            <img
              // height="25"
              width="20"
              src="/icons/cartegories.png"

            />
            <p>الاقسام</p>
          </a>

          <a href="/" className="flex flex-col items-center justify-center text-slate-600">
            <img
              height="25"
              width="25"
              src="/icons/cart.png"

            />
            <p>السله</p>
          </a>

          <a href="/" className="flex flex-col items-center justify-center text-slate-600">
            <img
              height="25"
              width="25"
              src="/icons/vedio.png"

            />
            <p>فيديو</p>
          </a>

          <a href="/" className="flex flex-col items-center justify-center text-slate-600">
            <img
              height="25"
              width="25"
              src="/icons/profile.png"

            />
            <p>حسابك</p>
          </a>



        </div>
      </div>
    </>
  )
}
export default App;