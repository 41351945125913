import React, { useEffect, useState } from 'react'
import GeneralServices from '../../../services/GeneralServices';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import { showErrorToast, showSuccessToast } from '../../../utils/toastUtils';

function AddUser({ close, fetchUsers }) {

    const [userData, setUserData] = useState({
        full_name: '',
        email: '',
        password: '',
        role: 'employee',
        currencies: [],
    });

    const [currencies, setCurrencies] = useState([]);

    const [validation, setValidation] = useState([]);

    const service = new GeneralServices();


    const handleAddEmployee = async () => {
        setValidation([])
        try {
            const res = await service.post('users', userData)
            showSuccessToast('تمت اضافة الموظف بنجاح')
            fetchUsers();
            close()
        } catch (error) {

            if (error.validationData) {
                setValidation(error.validationData)
            }
        }
    }


    const fetchCurrencies = async () => {
        try {
            const res = await service.get('currencies')
            setCurrencies(res)
        } catch (error) {
            console.log('error', error)
        }
    }


    useEffect(() => {
        fetchCurrencies()
    }, [])




    return <div className="p-1">

        <div className='mb-2'>
            <Input
                type="text"
                value={userData.full_name}
                onChange={(e) => setUserData({ ...userData, full_name: e.target.value })}
                placeholder="اسم الموظف"
                borderStyle="border border-gray-300"
            />
            {validation?.full_name && <p className='text-[10px] m-1 text-rose-700'>{validation?.full_name?.[0]}</p>}
        </div>

        <div className='mb-2'>
            <Input
                type="email"
                value={userData.email}
                onChange={(e) => setUserData({ ...userData, email: e.target.value })}
                placeholder="البريد الالكتوني"
                borderStyle="border border-gray-300"
            />

            {validation?.email && <p className='text-[10px] m-1 text-rose-700'>{validation?.email?.[0]}</p>}
        </div>

        <div className='mb-2'>
            <Input
                type="text"
                value={userData.password}
                onChange={(e) => setUserData({ ...userData, password: e.target.value })}
                placeholder="كلمه السر"
                borderStyle="border border-gray-300"
            />

            {validation?.password && <p className='text-[10px] m-1 text-rose-700'>{validation?.password?.[0]}</p>}
        </div>
        <div className='flex gap-2'>
            <p onClick={() => setUserData({ ...userData, role: 'employee' })} className={`${userData.role == 'employee' ? 'bg-green-500 text-white' : ''} px-2 cursor-pointer rounded-full`}>موظف</p>
            <p onClick={() => setUserData({ ...userData, role: 'admin' })} className={`${userData.role == 'admin' ? 'bg-green-500 text-white' : ''} px-2 cursor-pointer rounded-full`}>ادمن</p>

        </div>
        <div className='mt-2'>

            <SelectedCurrency currencies={currencies} userData={userData} setUserData={setUserData} />
        </div>
        <Button onClick={handleAddEmployee} text='اضافة' />
    </div>

}

const SelectedCurrency = ({ currencies, userData, setUserData }) => {


    const [activeCurrency, setActiveCurrency] = useState({ from: null, to: null });

    const handleAddCurrency = () => {
        const from_currency = currencies.find(c => c.id === parseInt(activeCurrency.from));
        const to_currency = currencies.find(c => c.id === parseInt(activeCurrency.to));
        const currency = { from: from_currency, to: to_currency };
        if (!userData.currencies.includes(currency)) {
            setUserData({ ...userData, currencies: [...userData.currencies, currency] })
        } else {
            showErrorToast('العملة موجودة بالفعل')
        }
    }



    return (
        <div>
            <div className='flex justify-between items-end'>
                <div class="form-group">
                    <label for="">من</label>
                    <select onChange={(e) => setActiveCurrency({ ...activeCurrency, from: e.target.value })} class="form-control" name="" id="">
                        {currencies.map((currency, index) => (
                            <option key={'from' + index} value={currency.id}>{currency.name}</option>
                        ))}
                    </select>
                </div>

                <div class="form-group">
                    <label for="">الي</label>
                    <select onChange={(e) => setActiveCurrency({ ...activeCurrency, to: e.target.value })} class="form-control">
                        {currencies.map((currency, index) => {
                            return <option key={'to' + index} value={currency.id}>{currency.name}</option>
                        })}
                    </select>
                </div>

                <div>
                    <button onClick={handleAddCurrency}>اضافة</button>
                </div>

            </div >
            <hr className='my-2' />
            <div className=' gap-2 mt-2 '>
                {userData?.currencies.map((currency, index) => (
                    <div key={index} className='flex justify-between'>
                        <p>{currency.from.name}</p>
                        <p>{currency.to.name}</p>
                        <p onClick={() => setUserData({ ...userData, currencies: userData.currencies.filter(c => c !== currency) })}>حذف</p>
                    </div>
                ))}
            </div>
        </div>
    )
}

const CurrencyBadge = ({ items, userData, setUserData }) => {

    const [expensData, setExpensData] = useState({ currency_id: null });

    return (

        <div className='flex overflow-x-scroll gap-2 p-2' style={{ scrollbarWidth: 'thin', scrollbarColor: 'gray transparent' }}>
            {items.map((item, index) => (
                <p
                    key={index}
                    onClick={() => setExpensData({ ...expensData, currency_id: item.id })}
                    className={`cursor-pointer flex items-center gap-2 rounded-full px-2 py-1 ${expensData.currency_id === item.id ? 'text-white bg-black' : 'border px-2'
                        }`}
                    style={{
                        whiteSpace: 'nowrap', // Ensures text remains on a single line
                        transition: 'all 0.3s ease', // Smooth visual interaction on change
                    }}
                >
                    <div
                        className={`w-[10px] h-[10px] rounded-full ${item.warning_balance > item.balance ? 'bg-red-500' : 'bg-green-500'
                            }`}
                    ></div>
                    {item.name}
                </p>
            ))}
        </div>
    )
}


export default AddUser