import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Input = ({
    type = 'text',
    error,
    value,
    onChange,
    placeholder,
    icon,
    borderStyle = 'border',
    size = 'sm',
    defaultValue,
    ...props
}) => {
    const sizeClasses = {
        sm: 'p-2 text-sm',
        md: 'p-3 text-base',
        lg: 'p-4 text-lg',
    };

    return (
        <>
            <div className={classNames('flex mt-1 items-center', borderStyle, sizeClasses[size], 'rounded-md')}>
                {icon && <img src={icon} alt="icon" className="mr-2 w-5 h-5" />}
                <input
                    type={type}
                    value={value}
                    onChange={onChange}
                    placeholder={placeholder}
                    defaultValue={defaultValue}
                    className="flex-1 outline-none bg-transparent"
                    {...props}
                />
            </div>

            {error && <p className='text-[10px] m-1 text-rose-700'>{error ?? ''}</p>}
        </>

    );
};

Input.propTypes = {
    type: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    icon: PropTypes.string,
    borderStyle: PropTypes.string,
    size: PropTypes.oneOf(['sm', 'md', 'lg']),
    defaultValue: PropTypes.string,
};

export default Input;
