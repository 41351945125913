import React, { useState } from 'react';
import GeneralServices from '../../../services/GeneralServices';
import { showErrorToast, showSuccessToast } from '../../../utils/toastUtils';
import BottomSheet from '../../../components/BottomSheet';
import Input from '../../../components/Input';
import Button from '../../../components/Button';

const TransactionCard = ({ transaction, fetchTransactions }) => {




    const [openTransaction, setTransaction] = useState(false)


    const handleAprove = () => setTransaction(true)




    return (
        <>
            {openTransaction && <BottomSheet title='تفاصيل العملية' isOpen={transaction} onClose={() => setTransaction(false)} >
                <TransactionDetails fetchTransactions={fetchTransactions} close={() => setTransaction(false)} transaction={transaction} />
            </BottomSheet>
            }

            <div className='shadow-lg bg-white mt-2 rounded-xl p-2'>
                <div className='flex justify-between'>
                    <div className='flex gap-2 items-center'>
                        <img src={transaction?.from_currency?.icon} alt='' className='w-6 h-6 rounded-full' />
                        <div>
                            <p>{transaction?.from_currency?.name}</p>
                            <p>{transaction?.from_balance}</p>
                            {/* <p className='text-[11px]'>{transaction.safe.name}</p> */}
                        </div>
                    </div>
                    <div className='flex gap-2 items-center'>
                        <img src={transaction?.to_currency?.icon} alt='' className='w-6 h-6 rounded-full' />
                        <div>
                            <p>{transaction?.to_currency?.name}</p>
                            <p className='text-[16px] font-bold'>{transaction?.to_balance}</p>
                            {/* <p className='text-[11px]'>{transaction.safe.name}</p> */}
                        </div>
                    </div>
                </div>
                <hr className='my-2' />
                <div>
                    <p className='text-gray-400'>{transaction?.note}</p>
                </div>
                <hr className='my-2' />
                <div className='flex flex-col gap-2'>
                    <div className='flex justify-between'>
                        <div className='flex gap-1 items-center'>
                            <div className='flex items-center gap-2'>
                                <img width="24" height="24" src="https://img.icons8.com/ios-filled/50/12B886/administrator-male--v1.png" alt="administrator-male--v1" />
                                <p className='text-gray-500 '>بواسطه</p> <p className='text-gray-500 font-bold'>{transaction?.creater?.full_name.split(' ').slice(0, 2).join(' ')}</p>
                            </div>
                        </div>

                        <div className='flex gap-1 items-center '>
                            <div className='flex items-center gap-2'>
                                <img width="24" height="24" src="https://img.icons8.com/material/24/12B886/administrator-male--v1.png" alt="administrator-male--v1" />

                                <p className='text-gray-500 '>المنفذ</p> <p className='text-gray-500 font-bold'>{transaction?.approval?.full_name.split(' ').slice(0, 2).join(' ')}</p>
                            </div>
                        </div>
                    </div>

                    <div className='flex justify-between'>


                        <div className='flex gap-1 items-center '>
                            <div className='flex items-center gap-2'>
                                <img width="24" height="24" src="https://img.icons8.com/pastel-glyph/64/12B886/calendar.png" alt="administrator-male--v1" />

                                <p className='text-gray-500 font-bold'>{formatDate(transaction?.created_at)}</p>
                            </div>
                        </div>


                        {/* <button onClick={() => handleAprove(transaction?.id)} className='border px-3  rounded-full'>تنفيذ</button> */}

                        {transaction.status === 0 ? <button onClick={handleAprove} className='border px-3  rounded-full'>تنفيذ</button> : <p>منفذ</p>}
                    </div>



                </div>
            </div>
        </>
    );
};


const TransactionDetails = ({ transaction, fetchTransactions, close }) => {


    const service = new GeneralServices()

    // عدد مرات التحويل
    const [TransactionData, setTransactionData] = useState({
        transaction_id: transaction?.id,
        count: 1
    })

    const handleAprove = async (id) => {

        try {
            const res = await service.post(`transaction/approve`, TransactionData)
            fetchTransactions()
            close()
            showSuccessToast('تم التحويل بنجاح')
        } catch (error) {
            console.log('error', error)
            showErrorToast('عفوا يوجد خطاء في عملية التحويل')
        }
    }
    return <div>
        <div>

            <p className='text-gray-400'>المبلغ المحول  </p>
            <Input
                label="المبلغ"
                placeholder={"المبلغ"}
                value={transaction?.to_balance}
                disabled
            />
        </div>
        <div className='mt-2'>
            <p className='text-gray-400'>عدد مرات التحويل </p>
            <Input
                label="المبلغ"
                onChange={(e) => setTransactionData({ ...TransactionData, count: e.target.value })}
                value={TransactionData.count}
                placeholder={"عدد مرات التحويل "}

            />
        </div>
        {transaction.status === 0 ? <Button onClick={handleAprove} text={'تنفيذ'} className='border px-3  rounded-full' /> : <p>منفذ</p>}

    </div>;
};

export default TransactionCard;

// Helper function to format date (you can implement this as needed)
const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
};
