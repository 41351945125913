import React, { useEffect, useState } from 'react'
import GeneralServices from '../../services/GeneralServices'
import { formatDate } from '../../utils/utility'
import { showSuccessToast } from '../../utils/toastUtils'
import TransactionCard from './Component/TransactionCard'

function TransactionListPage() {

    const [transactions, setTransactions] = useState([])

    const service = new GeneralServices()

    const fetchTransactions = async () => {
        try {
            const res = await service.get('transaction')
            setTransactions(res)
        } catch (error) {
            console.log('error', error)
        }
    }



    useEffect(() => {
        fetchTransactions()

    }, [])


    return (
        <div>
            <div className='flex justify-between items-center'>
                <h1 className='text-lg font-semibold'>سجل التحويلات</h1>


                <div className='flex gap-1 w-8 h-8 bg-red-600 rounded-full items-center justify-center'>
                    <p className='text-white'>  {transactions.length} </p>

                </div>
            </div>
            {transactions.map((transaction) => (

                <TransactionCard key={transaction.id} transaction={transaction} fetchTransactions={fetchTransactions} />
            ))}
        </div>
    )
}

export default TransactionListPage