// routes/routes.js

import React from "react";
import { Routes, Route } from "react-router-dom";



import Login from "../pages/Login";
import AppLayout from "../layoutes/AppLayout";
import WalletPage from "../pages/wallet/WalletPage";
import ExpensesPage from "../pages/expenses/ExpensesPage";
import TransactionPage from "../pages/transaction/TransactionPage";
import UsersPage from "../pages/users/UsersPage";
import SettingsPage from "../pages/settings/SettingsPage";
import TransactionListPage from "../pages/transaction/TransactionListPage";
import WalletTransactionsPage from "../pages/wallet/WalletTransactionsPage";
import UserLayout from "../layoutes/UserLayout";





const AppRoutes = () => {
  return (
    <Routes>



      {/* <Route path="/print/:id" Component={Print} /> */}
      {/* <Route Component={HomeLayout} >


        <Route path="/products-details/:id" Component={ProductDetails} />
      </Route> */}
      <Route path="/" Component={AppLayout} >
        <Route path="/wallet" Component={WalletPage} />
        <Route path="/wallet/transaction/:id" Component={WalletTransactionsPage} />

        <Route path="/transaction/list" Component={TransactionListPage} />
        <Route path="/users" Component={UsersPage} />

        <Route path="/transaction" Component={TransactionPage} />

        <Route path="/expenses" Component={ExpensesPage} />
        <Route path="/settings" Component={SettingsPage} />

      </Route>

      <Route path="/transactions" Component={UserLayout} />


      <Route path="*" element={<div></div>} />
      <Route path="/login" Component={Login} />
      {/* <Route path="/chat" Component={Chat} /> */}

    </Routes>
  );
};

export default AppRoutes;
