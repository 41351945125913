// import React from 'react';
// import { Bar, Line } from 'react-chartjs-2';
// import {
//     Chart as ChartJS,
//     CategoryScale,
//     LinearScale,
//     BarElement,
//     Title,
//     Tooltip,
//     Legend
// } from 'chart.js';

import React from 'react';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    LineElement,
    PointElement,
    CategoryScale,
    LinearScale,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';


ChartJS.defaults.font.family = "'Baloo Bhaijaan 2', cursive";

// ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
// Register the required components
ChartJS.register(
    LineElement,
    PointElement,
    CategoryScale,
    LinearScale,
    Title,
    Tooltip,
    Legend
);

const MyChart = () => {
    // Data for the chart
    // const data = {
    //     labels: ['ابريل', 'مايو', 'مارس', 'ابريل', 'مايو', 'يناير', 'جولاي'],
    //     datasets: [
    //         {
    //             label: 'الدخل الشهري',
    //             data: [65, 59, 80, 81, 56, 55, 40],
    //             backgroundColor: '#1eba83',
    //             borderColor: '#1eba83',
    //             borderWidth: 1,
    //             borderRadius: 5,
    //             borderSkipped: false,
    //         },
    //     ],
    // };

    const data = {
        labels: ['ياناير', 'فبراير', 'مارس', 'ابريل', 'مايو', 'يناير', 'جولاي'],
        datasets: [
            {
                label: '',
                // display: false,
                data: [100, 59, 80, 150, 56, 55, 200],
                // fill: true, // No fill below the line
                borderColor: '#000000', // Line color
                borderWidth: 3, // Line width
                pointBackgroundColor: 'gray-400', // Point color
                pointBorderColor: '#fff', // Point border color
                pointBorderWidth: 2, // Point border width
                tension: 0.4,
            },
        ],
    };

    // const options = {
    //     plugins: {
    //         title: {
    //             display: true,
    //             text: 'المبيعات خلال الفتره السابقه',
    //             font: {
    //                 family: "'Baloo Bhaijaan 2', cursive", // Custom font for title
    //             },
    //         },
    //         legend: {
    //             labels: {
    //                 font: {
    //                     family: "'Baloo Bhaijaan 2', cursive", // Custom font for legend
    //                 },
    //             },
    //         },
    //     },
    //     scales: {
    //         x: {
    //             ticks: {
    //                 font: {
    //                     family: "'Baloo Bhaijaan 2', cursive", // Custom font for x-axis
    //                 },
    //             },
    //         },
    //         y: {
    //             ticks: {
    //                 font: {
    //                     family: "'Baloo Bhaijaan 2', cursive", // Custom font for y-axis
    //                 },
    //             },
    //         },
    //     },
    //     // Bar width settings
    //     barThickness: 10, // Fixed bar width in pixels
    //     maxBarThickness: 50, // Maximum bar width in pixels
    //     minBarLength: 10, // Minimum bar length in pixels
    // };


    const options = {
        plugins: {
            title: {
                display: true,
                text: 'المبيعات الشهريه',
                font: {
                    family: "'Baloo Bhaijaan 2', cursive",
                    size: 18, // Title font size
                },
                padding: {
                    top: 10,
                    bottom: 10,
                },
            },
            legend: {
                labels: {
                    font: {
                        family: "'Baloo Bhaijaan 2', cursive",
                        size: 14, // Legend font size
                    },
                },
            },
        },
        scales: {
            x: {
                ticks: {
                    font: {
                        family: "'Baloo Bhaijaan 2', cursive",
                    },
                },
            },
            y: {
                ticks: {
                    font: {
                        family: "'Baloo Bhaijaan 2', cursive",
                    },
                },
            },
        },
    };

    return (
        <div>

            {/* <Bar data={data} options={options} />; */}
            <Line data={data} options={options} />
        </div>

    )
};

export default MyChart;
