import React from 'react'

function Button({ onClick, text, loading, width = '100%' }) {
    return (
        <div className='flex justify-center mt-2'>
            <div onClick={onClick} on className='border cursor-pointer border-black w-[80%] p-2 rounded-xl mt-2 text-center bg-black font-semibold text-white' style={{ width }}>
                <p>
                    {text}

                </p>
            </div>
        </div>
    )
}

export default Button